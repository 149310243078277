import requests from "./request"

//上传图片
export function UploadImg(parmas){
    return requests.post('/PublicController/imageUpdate',parmas)
}

//登录
export function AdminLogin(parmas){
    return requests.post('/Login/adminLogin',parmas)
}

//添加网点
export function NetWorkAdd(parmas){
    return requests.post('/Network/networkAddData',parmas)
}

//网点列表
export function NetWorkList(parmas){
    return requests.post('/Network/networkAllData',parmas)
}

//网点详情
export function NetWorkDetail(parmas){
	return requests.post("/Network/networkAllCountData",parmas)
}


//网点详情
export function NetWorkGetOne(parmas){
	return requests.post("/Network/networkUpdateShow",parmas)
}

//网点修改
export function NetWorkChange(parmas){
	return requests.post("/Network/networkUpdateData",parmas)
}

//计费添加
export function SetPayAdd(parmas){
	return requests.post("/Network/addChargingConfig",parmas)
}

//计费验证
export function SetPayVerification(parmas){
	return requests.post("/Network/isChargingConfig",parmas)
}

//分润规则验证
export function GetPayVerification(parmas){
	return requests.post("/Network/newIsNetworkDivideRule",parmas)
}

//分润规则添加
export function NetWorkRuleAdd(parmas){
	return requests.post("/Network/newNetworkDivideRuleAdd",parmas)
}

//分润规则删除
export function netWorlRuleDel(parmas){
	
	return requests.post("/Network/getDeleteRule",parmas)
}

//设备列表
export function DeviceList(parmas){
	return requests.post("/Device/deviceList",parmas)
}

//设备添加
export function DeviceAdd(parmas){
	return requests.post("/Device/deviceAddC",parmas)
}

//设备详情
export function DeviceInfo(parmas){
	return requests.post("/Device/deviceSelectOneShow",parmas)
}

//设备开机
export function Devicekaiji(parmas){
	return requests.post("/Device/deviceAddC",parmas)
}

//设备关机
export function Deviceguanji(parmas){
	return requests.post("/Device/deviceAddC",parmas)
}

//设备删除
export function DeviceDel(parmas){
	return requests.post("/Device/deviceAddC",parmas)
}

//设备查看
export function DeviceDetails(parmas){
	return requests.post("/Device/deviceAddC",parmas)
}

//设备编辑
export function DeviceEdit(parmas){
	return requests.post("/Device/deviceUpdate",parmas)
}

//会员列表
export function VipList(parmas){
	return requests.post("/VipUserData/vipUserList",parmas)
}

//会员查看
export function VipDetails(parmas){
	return requests.post("/VipUserData/vipOneUserList",parmas)
}

//会员操作
export function VipChange(parmas){
	return requests.post("/VipUserData/vipOneUpdate",parmas)
}

//解绑openid
export function VipChangeOpen(parmas){
	return requests.post("/VipUserData/wxUnbind",parmas)
}

//订单列表
export function OrderList(parmas){
	return requests.post("/OrderData/orderShow",parmas)
}


//订单查询
export function OrderDetails(parmas){
	return requests.post("/OrderData/orderGetOne",parmas)
}

//订单实时查询
export function OrderDetailsTen(parmas,headers){
	return requests.post("/OrderData/orderGetOne",parmas,{headers:{noLoad:"noload"}})
}


//管理员列表
export function adminList(parmas){
	return requests.post("/AdminUser/adminUserList",parmas)
}

//管理员删除
export function adminDel(parmas){
	return requests.post("/AdminUser/adminUserDelData",parmas)
}

//管理员查询
export function adminGetOne(parmas){
	return requests.post("/AdminUser/adminUserGetOne",parmas)
}

//管理员添加
export function adminAdd(parmas){
	return requests.post("/AdminUser/adminUserAddData",parmas)
}

//财务管理
export function caiwuList(parmas){
	return requests.post("/MoneyTableList/moneyList",parmas)
}

//跨店列表
export function kdList(parmas){
	return requests.post("/MoneyTableList/crossStoreNetworkData",parmas)
}

//洗车卡列表
export function cardList(parmas){
	return requests.post("/BlockType/blockTypeShow",parmas)
}

//洗车卡添加
export function cardAdd(parmas){
	return requests.post("/BlockType/blockTypeAddC",parmas)
}

//洗车卡修改
export function cardEdit(parmas){
	return requests.post("/BlockType/blockTypeUpdate",parmas)
}

//洗车卡删除
export function cardDel(parmas){
	return requests.post("/BlockType/deleteBlockType",parmas)
}

//洗车卡查询
export function cardOne(parmas){
	return requests.post("/BlockType/getOneBlockType",parmas)
}

//修改密码
export function changePassWord(parmas){
	return requests.post("/UserInfo/changePass",parmas)
}

//菜单列表
export function menuList(parmas){
	return requests.post("/Menu/menuList",parmas)
}

//菜单添加
export function menuAdd(parmas){
	return requests.post("/Menu/menuAdd",parmas)
}

//菜单编辑
export function menuEdit(parmas){
	return requests.post("/Menu/menuEdit",parmas)
}

//菜单查询
export function menuGetOne(parmas){
	return requests.post("/Menu/getOneById",parmas)
}

//菜单删除
export function menuDel(parmas){
	return requests.post("/Menu/menuDel",parmas)
}

//重置计费模式
export function chongzhi(parmas){
	return requests.post("/Network/recoveryDivideCost",parmas)
}

//核销下拉
export function HxSelect(parmas){
	return requests.post("/Writeoff/writeoffShow",parmas)
}

//核销列表
export function HxShow(parmas){
	return requests.post("/Writeoff/writeoffList",parmas)
}

//核销添加
export function HxSet(parmas){
	return requests.post("/Writeoff/writeoffAddData",parmas)
}

//核销查看
export function HxSetGetOne(parmas){
	return requests.post("/Writeoff/writeoffEdit",parmas)
}

//远程启动
export function qidong(parmas){
	return requests.post("/Device/startDevice",parmas)
}

//远程结算
export function jiesuan(parmas){
	return requests.post("/OrderData/adminJieSuan",parmas)
}

//手机端网点信息查询
export function netWorkInfoByOnly(parmas){
	return requests.post("/Home/netWorkInfoByOnly",parmas)
}

//设置权限
export function setPower(parmas){
	return requests.post("/AdminUserAuthority/updateRole",parmas)
}

//编辑权限
export function changePower(parmas){
	return requests.post("/AdminUserAuthority/roleUserUpdate",parmas)
}

//角色列表
export function roleList(parmas){
	return requests.post("/AdminUser/roleList",parmas)
}

//修改会员余额
export function VipCardChange(parmas){
	return requests.post("/VipCard/vipCardChange",parmas)
}

//查看会员余额
export function VipCardInfo(parmas){
	return requests.post("/VipCard/vipCardInfo",parmas)
}

//生成兑换卡二维码
export function shengCheng(parmas){
	return requests.post("/BlockType/blockQrCode",parmas)
}

//查看兑换卡二维码
export function SeeMa(parmas){
	return requests.post("/VipCard/vipCardInfo",parmas)
}

//查看兑换记录
export function dhList(parmas){
	return requests.post("/BlockType/getBlockVipData",parmas)
}


//短信列表查询
export function MessageList(parmas){
	return requests.post("/SmsPackage/smsPackageList",parmas)
}

//短信套餐添加
export function MessageAdd(parmas){
	return requests.post("/SmsPackage/smsPackageAdd",parmas)
}

//短信修改查询
export function MessageEdit(parmas){
	return requests.post("/SmsPackage/smsPackageOne",parmas)
}

//短信下单
export function MessageXd(parmas){
	return requests.post("/SmsPackage/smsPlaceOrder",parmas)
}

//短信使用列表
export function MessageUseList(parmas){
	return requests.post("/SmsPackage/smsPlaceAdminGetAll",parmas)
}

//短信用量明细
export function MessageUseInfo(parmas){
	return requests.post("/SmsPackage/smsPlaceVipData",parmas)
}

//短信店主列表
export function MessageUserBySearch(parmas){
	return requests.post("/SmsPackage/adminUserList",parmas)
}

//查看通知信息
export function allNotice(parmas){
	return requests.post("/Index/sendNotice",parmas)
}

//首页信息
export function indexTotal(parmas){
	return requests.post("/index/totalWD",parmas)
}

//首页网点
export function indexNetWork(parmas){
	return requests.post("/index/getWD",parmas)
}

//首页套餐排行
export function indexTaoCan(parmas){
	
	return requests.post("/index/taochan",parmas)
}

//首页门店营业额
export function indexYingYe(parmas){
	
	return requests.post("/index/yingyee",parmas)
}

//首页设备列表
export function indexDevList(parmas){
	
	return requests.post("/index/getdev",parmas)
}

//首页今天数据
export function indexTodayInfo(parmas){
	return requests.post("/index/nowTotalWD",parmas)
}

//首页充值排行榜
export function indexChongZhi(parmas){
	return requests.post("/index/chongzhiphb",parmas)
}

//首页消费排行榜
export function indexXiaoFei(parmas){
	return requests.post("/index/xiaofeiphb",parmas)
}

//首页转化率
export function indexZhuanHua(parmas){
	return requests.post("/index/getChangeData",parmas)
}

//首页趋势
export function indexQuShi(parmas){
	return requests.post("/index/getVipOrder",parmas)
}

//首页设备详情
export function indexDevInfo(parmas){
	return requests.post("/index/getDevInfo",parmas)
}

//数据分析门店数据概览
export function DataNetWorkTotal(parmas){
	return requests.post("/DataAnalysis/datashow",parmas)
}

//数据分析门店数据概览套餐排行
export function DataPackageList(parmas){
	return requests.post("/DataAnalysis/gettaochan",parmas)
}

//数据分析门店数据概览设备排行
export function DataDevList(parmas){
	return requests.post("/DataAnalysis/getDevList",parmas)
}

//数据分析用户行为洗车数据
export function XiCheList(parmas){
	return requests.post("/DataAnalysis/getXicheList",parmas)
}

//数据分析用户行为套餐使用率

export function TaoCanUseLv(parmas){
	return requests.post("/DataAnalysis/getTCBuyHZ",parmas)
}

//数据分析客流量

export function KeLiuLiang(parmas){
	return requests.post("/DataAnalysis/getVipLiuLiang",parmas)
}

/*
手机端接口
**/

//首页设备列表跳转
export function mobielDevList(parmas){
	return requests.post("/index/getDevList",parmas)
}

//数据分析数据概览
export function MobileDataAll(parmas){
	return requests.post("/DataMobile/getShujuGailan",parmas)
}

//数据分析客流量
export function MobileDataKeLiuLiang(parmas){
	return requests.post("/DataMobile/getVipLiuLiang",parmas)
}

//数据分析洗车数据
export function MobileDataXiCheShuJv(parmas){
	return requests.post("/DataMobile/getXicheData",parmas)
}

//数据分析套餐回购
export function MobileDataTaoCanHuiGou(parmas){
	return requests.post("/DataMobile/getTaoCanHG",parmas)
}

//数据分析套餐使用
export function MobileDataTaoCanShiYong(parmas){
	return requests.post("/DataMobile/getTaoCanList",parmas)
}

//数据分析会员数据
export function MobileDataVipData(parmas){
	return requests.post("/DataMobile/getUserData",parmas)
}

//数据分析跨店数据
export function MobileDataKuaDian(parmas){
	return requests.post("/DataMobile/getKuaDianData",parmas)
}

//数据分析营销-》储值销量
export function MobileDataChuZhi(parmas){
	return requests.post("/DataMobile/getChuZhiList",parmas)
}

//数据分析营销-》优惠券销量
export function MobileDataYouHuiQuan(parmas){
	return requests.post("/DataMobile/getYouHuiJuanList",parmas)
}

//数据分析营销-》赠送数据
export function MobileDataZengSong(parmas){
	return requests.post("/DataMobile/getZhengSongList",parmas)
}

//赠送金额-》首页

export function NetworkGiveIndex(parmas){
	return requests.post("/GiveMoney/getNetworkGiveIndex",parmas)
}

//赠送金额-》额度审批
export function ApplyForData(parmas){
	return requests.post("/GiveMoney/setApplyForData",parmas)
}

//赠送金额-》赠送会员金额
export function addGiveLog(parmas){
	return requests.post("/GiveMoney/addGiveLog",parmas)
}

//赠送金额-》额度申请列表
export function NetworkApplyForData(parmas){
	return requests.post("/GiveMoney/getNetworkApplyForData",parmas)
}

//赠送金额-》剩余次数
export function NetworkApplyForNum(parmas){
	return requests.post("/GiveMoney/getNetworkApplyForNum",parmas)
}

//赠送金额-》申请提交
export function addGiveApplyFor(parmas){
	return requests.post("/GiveMoney/addGiveApplyFor",parmas)
}

//赠送金额-》查看申请
export function applyForOneData(parmas){
	return requests.post("/GiveMoney/getApplyForOneData",parmas)
}

//赠送金额-》赠额调控
export function NetworkApplyForLog(parmas){
	return requests.post("/GiveMoney/getNetworkApplyForLog",parmas)
}

//赠送金额-》调整额度
export function MoneyApplyFor(parmas){
	return requests.post("/GiveMoney/getMoneyApplyFor",parmas)
}

//赠送金额-》操作人列表
export function ExamineAdminUserOnly(parmas){
	return requests.post("/GiveMoney/getExamineAdminUserOnly",parmas)
}

//赠送金额-》发放记录
export function ExamineApplyForLogData(parmas){
	return requests.post("/GiveMoney/getExamineApplyForLogData",parmas)
}

//赠送金额-》赠送记录
export function GiveLogData(parmas){
	return requests.post("/GiveMoney/getGiveLogData",parmas)
}

//停车场管理-》列表
export function parkNetworkList(parmas){
	return requests.post("/Network/parkNetworkList",parmas)
}

//停车场管理-》添加
export function parkNetworkAdd(parmas){
	return requests.post("/Network/parkNetworkAdd",parmas)
}

//停车场管理-》删除
export function parkNetworkDel(parmas){
	return requests.post("/Network/parkNetworkDel",parmas)
}

//其他管理-》活动形式列表查看
export function activityFormList(parmas){
	return requests.post("/Activity/getActivityFormList",parmas)
}

//其他管理-》活动形式添加
export function addActivityForm(parmas){
	return requests.post("/Activity/addActivityForm",parmas)
}

//其他管理-》活动形式编辑
export function activityFormOne(parmas){
	return requests.post("/Activity/setActivityFormOne",parmas)
}

//其他管理-》活动形式删除
export function delActivityForm(parmas){
	return requests.post("/Activity/delActivityForm",parmas)
}

//营销活动-》获取活动标签
export function activityType(parmas){
	return requests.post("/Activity/getActivityType",parmas)
}


//营销活动-》添加活动
export function addActivity(parmas){
	return requests.post("/Activity/addActivity",parmas)
}

//营销活动-》活动列表
export function listNetworkActivity(parmas){
	return requests.post("/Activity/listNetworkActivity",parmas)
}

//营销活动-》活动停止
export function stopNetworkActivity(parmas){
	return requests.post("/Activity/stopNetworkActivity",parmas)
}

//营销活动-》活动修改
export function setNetworkActivity(parmas){
	return requests.post("/Activity/setNetworkActivity",parmas)
}

//营销活动-》活动标签
export function getActivityType(parmas){
	return requests.post("/Activity/getActivityType",parmas)
}

//营销活动-》活动详情
export function activityDataList(parmas){
	return requests.post("/Activity/activityDataList",parmas)
}

//营销活动-》活动参与详情
export function vipActivityDataList(parmas){
	return requests.post("/Activity/getVipActivityDataList",parmas)
}

//营销活动-》活动折扣形式列表
export function activityVipType(parmas){
	return requests.post("/Activity/getActivityVipType",parmas)
}

//营销活动-》编辑前查询
export function networkActivityOne(parmas){
	return requests.post("/Activity/getNetworkActivityOne",parmas)
}


